import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../hooks/useForm";
import config from "../config.json"
import {getActiveLanguage, getStateCountries} from "../components/redux/getters";
import {
    axiosResetCountry,
    getAxiosCountries,
    getZipFileByCountry,
    setPdfLink
} from "../components/redux/userActions";
import {serverURL} from "../components/redux/store";
import OneTableRow from "../components/Table/OneTableRow";
import languages from "../assets/languages";

const AdminPage = () => {
    const dispatch = useDispatch()
    const countries = useSelector(getStateCountries)
    const pdfLink = useSelector(state => state.user.link)
    const language = useSelector(getActiveLanguage)
    const text = languages[language]
    const onResetCountryValues = (id) => dispatch(axiosResetCountry(id))
    const downloadBtnRef = useRef()
    const [isAdmin, setAdmin] = useState(false)
    const form = useForm({
        password: ""
    })
    const onDownloadZip = async (id) => {
        dispatch(getZipFileByCountry(id))
    }
    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (form.values.password === config.adminPassword) {
            setAdmin(true)
        }
    }
    useEffect(() => {
        if (pdfLink) {
            downloadBtnRef.current.click()
        }
        return () => {
            dispatch(setPdfLink(null))
        }
    }, [pdfLink, dispatch])
    const fetchCountries = () => dispatch(getAxiosCountries())
    useEffect(() => {
        if (isAdmin) fetchCountries()
    }, [dispatch, isAdmin])
    return (
        <div className={"container w-50"}>
            <a onClick={fetchCountries}
               href={`${serverURL}/${pdfLink}`}
               ref={downloadBtnRef}
               style={{display: "none"}}/>
            {!isAdmin ? <form onSubmit={onSubmitHandler}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">{text.admin.passMsg}</label>
                    <input {...form.binder.password} type="password"
                           className="form-control"
                           id="exampleInputEmail1"
                           aria-describedby="emailHelp"/>
                    <button type={"submit"} className={"btn bg-dark-green text-light mt-3"}>{text.admin.sign}</button>
                </div>
            </form> : <div className={"text-center mb-3"}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Country</th>
                        <th scope="col">Completed:</th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {countries.map((c, id) => {
                        return <OneTableRow c={c}
                                            key={id}
                                            language={language}
                                            onDeleteCountry={onResetCountryValues}
                                            onDownloadZip={onDownloadZip}
                                            id={id}/>
                    })}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}


export default AdminPage;

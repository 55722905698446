import {applyMiddleware, combineReducers, createStore} from "redux"
import thunk from "redux-thunk";
import {questionsReducer} from "../QuestionsPage/questionsReducer";
import userReducer from "./userReducer";
import axios from "axios"
import config from "../../config.json"

export const serverURL = config.backURI
const appAxios = axios.create({
    baseURL: serverURL
})


const rootReducer = combineReducers({
    questions: questionsReducer,
    user: userReducer
})
const store = createStore(rootReducer, applyMiddleware(thunk.withExtraArgument(appAxios)))

appAxios.interceptors.request.use(config => {
    try {
        config.headers["Authorization"] = store.getState().user.user.token;
    } catch (e) {
        // user non authorized
    }
    return config;
});

export default store
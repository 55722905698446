import {axiosGenerateResult} from "../redux/userActions";

export const SET_ACTIVE_QUESTION = "SET_ACTIVE_QUESTION"
export const SELECT_QUESTION_ANSWER = "SELECT_QUESTION_ANSWER"
export const CLEAR_QUESTIONS_STATE = "CLEAR_QUESTIONS_STATE"
export const SET_RESULT = "SET_RESULT"

export const clearQuestionState = () => ({type: CLEAR_QUESTIONS_STATE})
const setResult = (result) => ({
    type: SET_RESULT, payload: {result, isSended: true}
})
export const setActiveQuestion = (actionId) => {
    return {
        type: SET_ACTIVE_QUESTION,
        payload: {
            id: actionId,
        }
    }
}
export const selectQuestionAnswer = (questionId, subQuestionId, answerId) => {
    return (dispatch, getState) => {
        const activeQuestion = getState().questions.activeQuestion
        activeQuestion.selectedAnswers = activeQuestion.selectedAnswers.map(answer => {
            if (answer.id === answerId) {
                answer.questionId = answer.questionId ? null : subQuestionId
            }
            return answer
        })
        dispatch({type: SELECT_QUESTION_ANSWER, payload: {activeQuestion}})
    }
}

export const getResult = () => {
    return async (dispatch, getState, axios) => {
        const result = {
            eagle: 0,
            monkey: 0,
            panda: 0,
            bee: 0,
        }
        const questions = getState().questions.questions
        const isSended = getState().questions.isSended
        for (const question of questions) {
            const selectedAnswers = question.selectedAnswers
            switch (question.id) {
                case 1:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.panda += answer.id
                        } else if (answer.questionId === 2) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 3) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                case 2:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.bee += answer.id
                        } else if (answer.questionId === 3) {
                            result.panda += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 3:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 3) {
                            result.bee += answer.id
                        } else if (answer.questionId === 4) {
                            result.panda += answer.id
                        }
                    })
                    break
                case 4:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.panda += answer.id
                        } else if (answer.questionId === 2) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 3) {
                            result.bee += answer.id
                        } else if (answer.questionId === 4) {
                            result.monkey += answer.id
                        }
                    })
                    break
                case 5:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 2) {
                            result.panda += answer.id
                        } else if (answer.questionId === 3) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                case 6:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.panda += answer.id
                        } else if (answer.questionId === 2) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 3) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                case 7:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.bee += answer.id
                        } else if (answer.questionId === 3) {
                            result.panda += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 8:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.bee += answer.id
                        } else if (answer.questionId === 2) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 3) {
                            result.panda += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 9:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.bee += answer.id
                        } else if (answer.questionId === 2) {
                            result.panda += answer.id
                        } else if (answer.questionId === 3) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 4) {
                            result.monkey += answer.id
                        }
                    })
                    break
                case 10:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.panda += answer.id
                        } else if (answer.questionId === 3) {
                            result.bee += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 11:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.panda += answer.id
                        } else if (answer.questionId === 3) {
                            result.bee += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 12:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 2) {
                            result.panda += answer.id
                        } else if (answer.questionId === 3) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                case 13:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 2) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 3) {
                            result.panda += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                case 14:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.panda += answer.id
                        } else if (answer.questionId === 2) {
                            result.bee += answer.id
                        } else if (answer.questionId === 3) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 4) {
                            result.eagle += answer.id
                        }
                    })
                    break
                case 15:
                    selectedAnswers.forEach(answer => {
                        if (answer.questionId === 1) {
                            result.eagle += answer.id
                        } else if (answer.questionId === 2) {
                            result.monkey += answer.id
                        } else if (answer.questionId === 3) {
                            result.panda += answer.id
                        } else if (answer.questionId === 4) {
                            result.bee += answer.id
                        }
                    })
                    break
                default:
                    console.log("Попал не существующий")
            }
        }
        result.beePercent = result.bee * 100 / 150
        result.monkeyPercent = result.monkey * 100 / 150
        result.eaglePercent = result.eagle * 100 / 150
        result.pandaPercent = result.panda * 100 / 150
        result.heights = {
            eagleHeight: result ? result.eagle * 0.71 : 0,
            beeHeight: result ? result.bee * 0.71 : 0,
            monkeyHeight: result ? result.monkey * 0.71 : 0,
            pandaHeight: result ? result.panda * 0.71 : 0
        }
        if(!isSended){
            dispatch(axiosGenerateResult({
                ...result,
                ...result.heights,
            }))
        }
        dispatch(setResult(result))
    }
}
import React, {useEffect, useRef} from "react";
import headbee from "../../assets/head-bee.png"
import headeagle from "../../assets/head-eagle.png"
import headpanda from "../../assets/head-panda.png"
import headmonkey from "../../assets/head-monkey.png"
import resultDiagram from "./result-diagram.jpg"
import {useDispatch, useSelector} from "react-redux";
import {clearQuestionState, getResult} from "../QuestionsPage/questionActions";
import {Link, useHistory} from "react-router-dom";
import useForm from "../../hooks/useForm";
import {sendPdfOnMail} from "../redux/userActions";
import texts from "../../assets/languages";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon
} from "react-share"
import {SPANISH, GRUZIAN, GERMANY, MONGOL, RUS} from "../redux/userTypes";
import {getActiveLanguage, getMailMessage, getPdfLink, getUser, getUserLoader} from "../redux/getters";


const TestResultPage = () => {
    const dispatch = useDispatch()
    const appURL = window.location.origin;
    const history = useHistory()
    const toastRef = useRef()
    const mailMessage = useSelector(getMailMessage)
    const language = useSelector(getActiveLanguage)
    const isMailLoader = useSelector(getUserLoader)
    const text = texts[language].result
    const form = useForm({
        email: ""
    })
    const pdfLink = useSelector(getPdfLink)
    const user = useSelector(getUser)
    const result = useSelector(state => state.questions.result)
    const onNewRefresh = () => {
        dispatch(clearQuestionState())
        history.push("/questions")
    }
    const sendMessageOnMail = (type) => {
        // let link = 'https://personality.bpn.kg'
        // if (language === GRUZIAN) {
        //     link = 'https://personality.bpngeorgia.ge'
        // } else if (language === MONGOL) {
        //     link = 'https://personality.bpn.mn'
        // } else if (language === GERMANY) {
        //     link = 'https://personality.bpngeorgia.ge'
        // } else if (language === SPANISH) {
        //     link = 'https://personality.bpnnicaragua.com'
        // }
        if (type === "isEmail") {
            dispatch(sendPdfOnMail({
                filepath: pdfLink,
                text: "",
                email: form.values.email,
                toastRef: toastRef
            }, "isEmail", `${text.emailMessageToFriend} ${appURL}`))
        } else {
            dispatch(sendPdfOnMail({
                filepath: pdfLink,
                text: "",
                email: user.email,
                toastRef: toastRef,
            }))
        }

    }

    useEffect(() => {
        dispatch(getResult())
    }, [dispatch]);
    return (
        <div className={"container w-75"}>
            <h5 className={"text-medium-weight"}>{text.title}</h5>
            <div className={"row bg-dark-green border border-dark"}>
                <div className="col-md-6  border-end border-dark">

                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <h6 className={"text-white pt-1"}>
                        {text.type}
                    </h6>
                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <h6 className={"text-white pt-1"}>
                        {text.balls}
                    </h6>
                </div>
                <div className="col-md-2 col-3">
                    <h6 className={"text-white pt-1"}>
                        {text.percents}
                    </h6>
                </div>
            </div>
            <div className={"row border border-top-0 border-dark"}>
                <div className="col-12 border-bottom col-md-6 border-end border-dark">
                    <p className={"text-light-weight text-size"}>
                        {text.eagleBall}
                    </p>
                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <img src={headeagle} alt="head eagle" className={"w-100"}/>
                </div>
                <div className="col-md-2 col-3 border-end border-dark d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.eagle}
                    </h6>
                </div>
                <div className="col-md-2 col-3 d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.eaglePercent.toFixed(0)}%
                    </h6>
                </div>
            </div>
            <div className={"row border border-top-0 border-dark"}>
                <div className="col-12 border-bottom col-md-6 border-end border-dark">
                    <p className={"text-light-weight text-size"}>
                        {text.monkeyBall}
                    </p>
                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <img src={headmonkey} alt="head monkey" className={"w-100"}/>
                </div>
                <div className="col-md-2 col-3 border-end border-dark d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.monkey}
                    </h6>
                </div>
                <div className="col-md-2 col-3 d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.monkeyPercent.toFixed(0)}%
                    </h6>
                </div>
            </div>
            <div className={"row border border-top-0 border-dark"}>
                <div className="col-12 col-md-6 border-bottom border-end border-dark">
                    <p className={"text-light-weight text-size "}>
                        {text.pandaBall}
                    </p>
                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <img src={headpanda} alt="head panda" className={"w-100"}/>
                </div>
                <div className="col-md-2 col-3 border-end border-dark d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.panda}
                    </h6>
                </div>
                <div className="col-md-2 col-3 d-flex align-items-center justify-content-center">
                    <h6 className={""}>
                        {result && result.pandaPercent.toFixed(0)}%
                    </h6>
                </div>
            </div>
            <div className={"row border border-top-0 border-dark"}>
                <div className="col-12 col-md-6 border-bottom border-end border-dark">
                    <p className={"text-light-weight text-size"}>
                        {text.beeBall}
                    </p>
                </div>
                <div className="col-md-2 col-3 border-end border-dark">
                    <img src={headbee} alt="head bee" className={"w-100"}/>
                </div>
                <div className="col-md-2 col-3 border-end border-dark d-flex align-items-center justify-content-center">
                    <h6 className={"text-center pt-1"}>
                        {result && result.bee}
                    </h6>
                </div>
                <div className="col-md-2 col-3 d-flex align-items-center justify-content-center">
                    <h6>
                        {result && result.beePercent.toFixed(0)}%
                    </h6>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className="col-12 position-relative">
                    <div className={"eagle-ball bg-red position-absolute py-3 px-4"}>
                        {result && result.eagle}</div>
                    <div className={"bg-light-blue bee-ball position-absolute py-3 px-4"}>
                        {result && result.bee}</div>
                    <div className={"bg-yellow monkey-ball position-absolute py-3 px-4"}>
                        {result && result.monkey}</div>
                    <div className={"bg-light-green panda-ball position-absolute py-3 px-4"}>
                        {result && result.panda}</div>
                    <div className={"eagle-square-dashed"} style={{height: result.heights.eagleHeight + "%"}}/>
                    <div className={"monkey-square-dashed"} style={{height: result.heights.monkeyHeight + "%"}}/>
                    <div className={"bee-square-dashed"} style={{height: result.heights.beeHeight + "%"}}/>
                    <div className={"panda-square-dashed"} style={{height: result.heights.pandaHeight + "%"}}/>
                    {/*<div className={"position-absolute border-result-dashed " +*/}
                    {/*"border border-2 border-dark"}/>*/}
                    <img src={resultDiagram} alt="diagram" className={"w-100"}/>
                </div>
            </div>
            <p className={"text-light-weight text-size"}>
                {text.resultTxt1}
            </p>
            <p className={"text-light-weight text-size"}>
                {text.resultTxt12}
                <Link to={"/fourtendentions"} className={"mx-1"}>
                    {text.resultTxtLink}
                </Link>
                {text.resultTxt2}
            </p>
            <p className={"text-medium-weight text-size"}>
                {text.resultTxtBold}
            </p>
            <p className={"text-light-weight text-size"}>
                {text.resultTxt3} <a href={text.resultBpnLink}
                                     rel={"noreferrer"}
                                     target={"_blank"}>{text.resultTxt4}</a>
                {text.resultTxt5}
            </p>
            <div className={"row my-5"}>
                <div className={"col-md-4 mb-2 col-sm-12"}>
                    <button onClick={sendMessageOnMail}
                            id="liveToastBtn"
                            disabled={isMailLoader}
                            type={"button"}
                            className={"py-1 w-100 border-dark btn text-light btn-lg bg-dark-green"}>
                        {text.sendMailBtn}
                    </button>
                    <p className={"text-light-weight mb-0 mt-4 text-size"}>{text.shareWithFriendsBtn}</p>
                    <div className={"d-flex flex-wrap justify-content-between w-100"}>
                        <FacebookShareButton className={"me-2 mb-2"}
                                             quote={`${text.emailMessageToFriend} ${appURL}`}
                                             url={`${appURL}`}>
                            <FacebookIcon className={"w-100"}/>
                        </FacebookShareButton>
                        <LinkedinShareButton className={"me-2 mb-2"}
                                             url={`${appURL}`}
                                             title={`${text.emailMessageToFriend} ${appURL}`}>
                            <LinkedinIcon className={"w-100"}/>
                        </LinkedinShareButton>
                        <TwitterShareButton className={"me-2 mb-2"}
                                            title={`${text.emailMessageToFriend} ${appURL}`}
                                            url={`${appURL}`}>
                            <TwitterIcon className={"w-100"}/>
                        </TwitterShareButton>
                        <WhatsappShareButton className={"me-2 mb-2"}
                                             title={`${text.emailMessageToFriend}`}
                                             url={`${appURL}`}>
                            <WhatsappIcon className={"w-100"}/>
                        </WhatsappShareButton>
                        <button className={"btn p-0 mb-2"}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal">
                            <EmailIcon className={"w-100"}/>
                        </button>
                    </div>
                </div>
                <div className={"col-md-4 col"}/>
                <div className={"col-md-4 mb-2 mt-3 col-sm-12"}>
                    <button onClick={onNewRefresh}
                            className={"btn btn-large bg-gray border border-dark mb-3 w-100"}>
                        {text.restartBtn}
                    </button>
                    <a href={text.backOnBtnLink} target={"_blank"} rel={"noreferrer"}
                       className={"btn btn-large bg-light-green border border-dark w-100"}>
                        {text.backOnMainBtn}
                    </a>
                </div>
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{text.emailHead}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="forusername"
                                       className="form-label">{text.emailMessage}</label>
                                <input id="forusername" {...form.binder.email}
                                       type="email" className="form-control"
                                       placeholder="qwerty@mail.ru"/>
                                <div id="emailHelp"
                                     className="form-text" style={{whiteSpace: "pre-line"}}>
                                    {text.emailPlaceholder}
                                    <p>
                                        {text.emailMessageToFriend} <a href={appURL}>{text.emailLinkTxt}</a>
                                    </p>
                                </div>
                                {mailMessage && <div className="alert alert-success mt-3" role="alert">
                                    {text.emailSuccessTxt}
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => sendMessageOnMail("isEmail")}
                                type="button"
                                disabled={isMailLoader}
                                className="btn border-dark text-light bg-dark-green">{text.emailSendBtn}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div data-autohide="true"
                 ref={toastRef}
                 className="toast position-fixed mb-5 d-none"
                 style={{
                     bottom: "20%",
                     left: "30%",
                     background: "#fff"
                 }}
                 role="alert" aria-live="assertive"
                 aria-atomic="true">
                <div className="toast-header">
                    <span className={"bg-success rounded me-2"} style={{width: 20, height: 20}}/>
                    <strong className="me-auto">
                        {text.goodMessageAnswer}
                    </strong>
                </div>
                <div className="toast-body">
                    {text.goodMessageAnswer2}
                </div>
            </div>
        </div>
    );
};

export default TestResultPage;
